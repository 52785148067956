import React from 'react'

import { Flex, Text } from 'components'
import { PhoneLink } from 'components/ContactLink'

const Footer = ({ title }) => {
  return (
    <Flex
      id="telRequest"
      sx={{
        px: 4,
        py: 2,
        mb: 'env(safe-area-inset-bottom)',
        alignItems: 'center',
        justifyContent: 'space-between',
        bg: 'colorBlue5',
        borderBottomLeftRadius: 'default',
        height: 40,
        flexShrink: 0,
      }}
    >
      <Text
        sx={{
          fontSize: 0,
          color: 'darkNavy40',
        }}
      >
        Direkt anrufen
      </Text>
      <PhoneLink
        id="telRequest"
        sx={{
          fontSize: 1,
          fontWeight: 'bold',
          color: 'purple',
          textDecoration: 'none',
        }}
      />
    </Flex>
  )
}

export default Footer
